import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Accessibility is a joint project. To ensure that a website is easy to use for all users, accessibility should be
considered throughout the entire development process. How can designers best contribute?`}</p>
    <p>{`As a web developer and requirements engineer, I work closely with UI/UX designers. Based on the requirements, they
develop designs and `}<a parentName="p" {...{
        "href": "https://medium.com/sketch-app-sources/what-is-a-design-prototype-the-complete-guide-f79cce54c50e"
      }}>{`prototypes for the user interface`}</a>{`,
which developers later implement. This work is often very visually dominated: Which colors are used? How do we best
take the customer's corporate identity into account?`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAwD/xAAWAQEBAQAAAAAAAAAAAAAAAAACAQP/2gAMAwEAAhADEAAAAQZVzQyVP//EABoQAAICAwAAAAAAAAAAAAAAAAECABEDEBL/2gAIAQEAAQUCdgXRrnJi4xYpdf/EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/ASf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAgMAAAAAAAAAAAAAAAABERAhAAKB/9oACAEBAAY/AtR3LLpqUI//xAAcEAACAgIDAAAAAAAAAAAAAAAAARExIUFRgeH/2gAIAQEAAT8hceMTkVXs/YLkLaH0K1o2TFH/2gAMAwEAAgADAAAAEM8v/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARQf/aAAgBAwEBPxAckNv/xAAWEQEBAQAAAAAAAAAAAAAAAAAxAAH/2gAIAQIBAT8QSwv/xAAcEAEAAgIDAQAAAAAAAAAAAAABABEhMUFRcWH/2gAIAQEAAT8Q7Qo16iErhUAAaA+RFpct4jAGzFuVP2Cr3B6DjyGjAn//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Two employees of a flower store look at the tablet on the table in front of them.",
          "title": "Two employees of a flower store look at the tablet on the table in front of them.",
          "src": "/static/4c5c8363d64cafe6061df1b14de4675b/e5166/pexels-amina-filkins-women-tablet.jpg",
          "srcSet": ["/static/4c5c8363d64cafe6061df1b14de4675b/f93b5/pexels-amina-filkins-women-tablet.jpg 300w", "/static/4c5c8363d64cafe6061df1b14de4675b/b4294/pexels-amina-filkins-women-tablet.jpg 600w", "/static/4c5c8363d64cafe6061df1b14de4675b/e5166/pexels-amina-filkins-women-tablet.jpg 1200w", "/static/4c5c8363d64cafe6061df1b14de4675b/b17f8/pexels-amina-filkins-women-tablet.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Amina Filkins / pexels.com`}</em></p>
    <p>{`When designers tackle accessibility, they often think of the `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/use-of-color.html"
      }}>{`use of color`}</a>{`
and `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/contrast-minimum"
      }}>{`sufficient contrast ratios`}</a>{`. But designs can do
even more: accessibility annotations convey important information about the structure and semantics of the website.`}</p>
    <h2>{`The A11y Annotation Kit for Figma`}</h2>
    <p>{`There are various programs for creating prototypes. My colleagues like to work with `}<a parentName="p" {...{
        "href": "https://www.figma.com"
      }}>{`Figma`}</a>{`,
a collaborative platform for UI/UX design.`}</p>
    <p>{`To document accessibility considerations there, designers can define suitable elements themselves or work with ready-made
libraries such as the `}<a parentName="p" {...{
        "href": "https://www.figma.com/community/file/953682768192596304/a11y-annotation-kit"
      }}>{`A11y Annotation Kit`}</a>{`.
This offers a wide range of tools to mark up semantic information in the design. Let's take a closer look at a few of them.`}</p>
    <h2>{`Mark up Headings`}</h2>
    <p>{`Sighted users usually scan a website for headings to find what they are looking for. Headings are also of central importance
for blind and visually impaired users: According to the `}<a parentName="p" {...{
        "href": "https://webaim.org/projects/screenreadersurvey10/#heading"
      }}>{`latest survey by WebAIM`}</a>{`,
9 out of 10 screen reader users say that meaningful heading levels are very or somewhat useful for them.`}</p>
    <p>{`Headings must be marked up semantically so that screen readers can recognize them as headings. On websites, this is done with
the HTML tags `}<InlineCode mdxType="InlineCode">{`h1`}</InlineCode>{` to `}<InlineCode mdxType="InlineCode">{`h6`}</InlineCode>{`. Users can then use keyboard shortcuts to jump from
heading to heading, for example, and thus get a quick overview.`}</p>
    <p>{`Designers should consider as early as possible how the content of a page can be
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/tutorials/page-structure/headings/"
      }}>{`meaningfully structured with headings`}</a>{`. In the final design, all
headings and their level (1 to 6) should be annotated. Here's an example:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.333333333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAACxLAAAsSwGlPZapAAACPklEQVQoz12S32vSURjG/ds2N0XUy+6W5KhReZ2oUJOWNtGv36+iU+cqXFj34s0ciJg/mKkx6KIbf02dujmF+TNmT7xv06gDDy8cOJ/zPM85sp2dHRgMBuj123j8ZBt6/SNsbT2ETqfD5uYmlEolz42NDZZcLsf6+jrW1tagUqmg1WqhVquh0WigUCggMxqNMJvNMJtNeP7sBd6/iyKT+YKTkwROT0+RyWSQz+eRy+WQzWb/UTgcht/vh9VqhclkgsVigUySJIiiBJ9Pgu6BDx/DZcx/jtBsttDtdnF1dYVOp4PxeIz/V6FQQCwWQzQaRSAQ4AtkLpcLJEFwYffVG+RzZwyp1Wqo1+s8q9Uqms0mLi4uUKlUWLPZDOl0mmEE8vl87FbmcDjgdDpBTvf336JcLmM0GrEjOkSaTqerSfuku7s7riGRSCASiUAURQSDQcgI5na7IUkibDYbisUSbm767KzRaLArmuSWnJJojxb1GI/HcXx8zA4PDw//Akl2uw2lUpEjL2NeXl7i+vqa1ev1uM/BYMDAZDLJrigqdchAQRDuOxSw99qBwlkJ0+mEDw2HQ9ze3nLUyWSyqmD5QBSXzhI0FAqx7oECRMmJp7pP+PzhBwbDHur1BndJINKyx2WXtOhbeb1eBpE7Bi4fxeORsPvShdLX7wB+rTqk2P1+n2ug2DQp+nw+RyqVgsfjYYcHBwd/IpNl6o9eyW7fw/n5NywWC/4aBCUg9dZut1dqtVoMpA7J4dHREbuj+RvdSrWIwAKcuAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A design with two headings marked as h2 and h3.",
          "title": "A design with two headings marked as h2 and h3.",
          "src": "/static/8391eb26dfd93cbac2c5ff5453a6265d/c1b63/website_a11y_annotation_headings_en.png",
          "srcSet": ["/static/8391eb26dfd93cbac2c5ff5453a6265d/5a46d/website_a11y_annotation_headings_en.png 300w", "/static/8391eb26dfd93cbac2c5ff5453a6265d/0a47e/website_a11y_annotation_headings_en.png 600w", "/static/8391eb26dfd93cbac2c5ff5453a6265d/c1b63/website_a11y_annotation_headings_en.png 1200w", "/static/8391eb26dfd93cbac2c5ff5453a6265d/d61c2/website_a11y_annotation_headings_en.png 1800w", "/static/8391eb26dfd93cbac2c5ff5453a6265d/97a96/website_a11y_annotation_headings_en.png 2400w", "/static/8391eb26dfd93cbac2c5ff5453a6265d/fc83e/website_a11y_annotation_headings_en.png 2596w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Screenshot: © Alexander Lehner`}</em></p>
    <p>{`Two questions will constantly accompany you: What makes a headline a headline? Which headline hierarchy makes sense?
To make your life a little easier, here are a few learnings and tips:`}</p>
    <ul>
      <li parentName="ul">
        <strong>Headings structure the content of the page:</strong> A heading introduces a new section of content and should describe it.
Large font, bold print or a separate line do not automatically turn a text into a heading!
      </li>
      <li parentName="ul">
        <strong>Never skip a heading level!</strong> The most important heading should be marked as <InlineCode mdxType="InlineCode">h1</InlineCode>,
followed by <InlineCode mdxType="InlineCode">h2</InlineCode>, <InlineCode mdxType="InlineCode">h3</InlineCode>, etc. Headings with the same or higher rank start a new section.
Headings with a lower rank start new subsections.
      </li>
      <li parentName="ul">
        <strong>Separate styling and semantics:</strong> The visual appearance of a heading should never determine which heading level
is used! My advice: Define your own heading classes such
as <InlineCode mdxType="InlineCode">heading-sm</InlineCode>, <InlineCode mdxType="InlineCode">heading-md</InlineCode>, <InlineCode mdxType="InlineCode">heading-lg</InlineCode>, etc.
This allows you to style headings regardless of their position in the hierarchy.
      </li>
      <li parentName="ul">{`In some cases, a `}<a parentName="li" {...{
          "href": "https://www.tpgi.com/the-anatomy-of-visually-hidden/"
        }}>{`visually hidden heading`}</a>{` can also make sense in order to
improve the page structure.`}</li>
      <li parentName="ul">
        <strong>Be creative!</strong> A perfect heading structure is hard work. Make a draft. Get feedback from outside. Refine your
designs step by step. No one is born a master 😉
      </li>
    </ul>
    <h2>{`Alternative Text for Images`}</h2>
    <p>{`Missing or poor alternative text is one of the most common barriers on websites. According to the
`}<a parentName="p" {...{
        "href": "https://webaim.org/projects/million/"
      }}>{`WebAIM Million 2023 study`}</a>{`, over one third of the images on popular websites have
missing, questionable, or repetitive alternative text.`}</p>
    <p>{`Design prototypes should therefore define alternative texts for image content so that visually conveyed information is also
accessible for screen reader users. You should consider the following points:`}</p>
    <ul>
      <li parentName="ul">
        <strong>Functional images:</strong> If a button or link only contains an image or icon, the alternative text must describe
the purpose of the element.
      </li>
      <li parentName="ul">
        <strong>Informative images:</strong> These are images that convey relevant information. Here, the design should specify a
concrete alternative text or at least communicate that a meaningful alternative text must be defined.
      </li>
      <li parentName="ul">
        <strong>Decorative images:</strong> If images are only intended to embellish the page, then they must be hidden from assistive
technologies. The accessibility annotation can represent this with <InlineCode mdxType="InlineCode">alt=""</InlineCode>.
      </li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.333333333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAACE4AAAhOAFFljFgAAACkUlEQVQozz2Sy09TQRjF+8/4F7jzL3BvYqJBKi3lkaiEgDwkEAFZFIM1iFDbe9vSF7f30QvlkQBNjaEtDwnyclkCoaUEShcs2P7MDMHFyZmZfHPmfGc+R1dXFz09PXR2dtLd3U1HRwe9vb20tbXR2tpKS0sLHo+H5uZm3G63hMvlorGxEbfLJfeNztc4nU00NDTg8Pl8+Hxf/2Pq+zR7e3scHBywv7/P7u4uxWJRYnt7W+4PDw/Z2dlhTtPQ5pIo/m+ElWmCAT+OSGSWeDRMMhYmGlawTY16vUa5XKFSqVAulyULXF9fU6/Xubu74+amztrqCnFjhZcDNs/7F/jsN3EoSpCJqRCffGHGvqh4p5IUtvYoFvIsLy8zODhIe3u7bFtRFDY3N6X7UqnEz1yWpLnCqw8pGgZMJvw6jpAS4Nl7g8eeFZ60LfLoRZqW0QxbhV+sr2cZGxuTuYqsE4kEGxsbsu2TUolcLsf62iq2OUfG1klbBo6QqtDvjdL+McnbkQRvRlMs5v6wVcyTzxeIRqMMDw/j9XoJBAIyV5HxyckJmUyG+fkFtJROStcxTQuHqqrMhhViEYWw+gPb0vi9s0kms0g6bTEyMoKmaVIwFouRzWaxbZvT01OSySS6niJtCTFTQgoqqkpQUZmZ8ZO254nF4jidTvr6+iQvLS3JkRofH5cuJycnOTo6wjAM6S4Q0dF1414wGAwiREOhEGItioSLpqYmhoaGpKhwKeZU1IlMBY6P/2JbBtOqxtN3BqG4xYJtiV9WpNiDoGjj6uqKWq0mx6RarXJxcSH54fz29pZq9RJdF9mZhBNpyZZl3guKlx8g8hKXz8/POTs7k3x5eSnPHh4QcyjERa1lWczblmSBf3pYiV1px2b6AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A design with several images for which alternative texts are defined.",
          "title": "A design with several images for which alternative texts are defined.",
          "src": "/static/09307b20e40bed26b6562872f932583c/c1b63/website_a11y_annotation_alt_text_en.png",
          "srcSet": ["/static/09307b20e40bed26b6562872f932583c/5a46d/website_a11y_annotation_alt_text_en.png 300w", "/static/09307b20e40bed26b6562872f932583c/0a47e/website_a11y_annotation_alt_text_en.png 600w", "/static/09307b20e40bed26b6562872f932583c/c1b63/website_a11y_annotation_alt_text_en.png 1200w", "/static/09307b20e40bed26b6562872f932583c/d61c2/website_a11y_annotation_alt_text_en.png 1800w", "/static/09307b20e40bed26b6562872f932583c/058d6/website_a11y_annotation_alt_text_en.png 2277w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Screenshot: © Alexander Lehner`}</em></p>
    <p>{`You can find more information and some negative examples in my article
`}<a parentName="p" {...{
        "href": "/how-not-to-use-images.en/"
      }}>{`“A Picture Says More Than a 1000 Words – Unless You're Blind!”`}</a>{`.`}</p>
    <h2>{`Button or Link?`}</h2>
    <p>{`Links are underlined. Buttons are clickable boxes. Aren't they? 🤔`}</p>
    <p>{`Visually, links and buttons are often not clearly distinguishable from each other. In some designs, links look like classic
buttons, and vice versa. This is usually not a problem for sighted users: they only need to understand that these elements
are interactive.`}</p>
    <p>{`However, the semantic role of the elements is particularly important for assistive technologies. Depending on whether the
screen reader announces an element as a link or a button, users expect different behavior:`}</p>
    <ul>
      <li parentName="ul">
        <strong>Links</strong> navigate to a new resource, e.g. another website or another section on the same page.
You leave the current context.
      </li>
      <li parentName="ul">
        <strong>Buttons</strong>, on the other hand, trigger an action in the same context, e.g. a pop-up menu or playing a video.
      </li>
    </ul>
    <p>{`This means that the appearance is secondary. The function of the element is crucial. I recommend you read the
article `}<a parentName="p" {...{
        "href": "https://marcysutton.com/links-vs-buttons-in-modern-web-applications"
      }}>{`“Links vs. Buttons in Modern Web Applications”`}</a>{`,
which delves deeper into the topic.`}</p>
    <p>{`Dear designers: Mark links and buttons according to their function. And please add a legend to your design that tells developers
to use native elements for this. So `}<InlineCode mdxType="InlineCode">{`a`}</InlineCode>{` for links and `}<InlineCode mdxType="InlineCode">{`button`}</InlineCode>{` for buttons. Your
users will love you for it! 🤩`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      